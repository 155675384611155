import React from 'react';
import Lottie from 'react-lottie';
import DesktopMacIcon from '@mui/icons-material/DesktopMac';
import Banner from './assets/images/banner.png'
import CloudUploadIcon from '@mui/icons-material/CloudUpload';
import HomeWorkIcon from '@mui/icons-material/HomeWork';
import BuildIcon from '@mui/icons-material/Build';
import PhoneIphoneIcon from '@mui/icons-material/PhoneIphone';
import network from "./assets/images/animations/network.json";
import chat from "./assets/images/animations/ai-chat.json";
import venue from "./assets/images/animations/venue.json";
import meaning from "./assets/images/animations/meaning.json";
import message from "./assets/images/animations/chat.json";
import overview from "./assets/images/animations/overview.json";
import "@fontsource/open-sans";
import './App.css';

class App extends React.Component {
 
  constructor(props) {
    super(props);
    this.state = {
      isStopped: false,
      isPaused: false,
    };

  }

  componentWillMount(){
    this.setState({},_=>this.setSpeed())
  }

  setSpeed(x){
    this.setState({speed: x})
  }

  welcomeSection = () => {
    return <section
      style={{
        marginBottom: 50,
      }}>
      
      <div style = {{
        display: 'flex',
        alignItems: 'center',
      }}>
      
        <div id = "centerTitle" style = {{
          position: 'absolute',
          justifyContent: 'center',
          alignItems: 'center',
          top: window.innerHeight * 0.1,
          left: "10%",
          width: '100%',
          height: '100%',
        }}>

          <img
            src={Banner}
            alt={"banner"}
            style={{
              display: 'block',
              width: '80%',
              height: 'auto',
              backgroundColor: 'rgba(255, 255, 255, 0.5)',
              borderRadius: 20,
            }}
          />

        </div>

        <Lottie
          options={{
            loop: true,
            autoplay: true,
            animationData: network,
            rendererSettings: {
              preserveAspectRatio: 'xMidYMid'
            }
          }}
          isStopped={false}
          isPaused={false}
          speed={1}
        />

      </div>

    </section>
  }

  engageSection = () => {
    return <section style = {{
        marginTop: -window.innerHeight * 0.02,
        marginBottom: 100,
        alignItems: 'center',
        textAlign: 'center',
      }}>

      <div>
        <h3
          style={{
            fontSize: 40,
            fontFamily: "Open Sans",
          }}
          >
          Start Networking Today!
        </h3>

        <br/>

        <div style={{
          width: '100%',
        }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: chat,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid'
              }
            }}
            width={'40%'}
            isStopped={false}
            isPaused={false}
            speed={1}
          />
        </div>

        <p
          style={{
            fontFamily: "Open Sans",
            fontSize: 20,
            lineHeight: 1.4,
          }} >
          Experience the <span className='tenx'>10x Network</span>'s AI-enhanced,<br/>
          chat-based networking solution that connects you<br/>
          with like-minded people and companies.
        </p>
        <br/>

        <a
          href={"/signup"}
          className={"waves-effect waves-dark tenx-button btn-small white-text"}
          >
          Create Account
        </a>

      </div>
      
    </section>
  }

  featuresSection = () => {
    return <section style = {{
        marginTop: window.innerHeight * 0.2,
        marginBottom: 50,
        display: 'flex',
        alignItems: 'center',
      }}>

      <div className="row">

        <div className="col s4">
          <div style={{
              width: '100%',
              alignItems: 'center',
              alignSelf: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              justifySelf: 'center',
            }}>

            <p
              className={'acsa'}
              style={{
                marginTop: 25,
                fontFamily: "Open Sans",
                textAlign: 'center',
                fontSize: 24,
                marginBottom: 20,
              }} >
              Venue-based
            </p>
            
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: venue,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid'
                }
              }}
              width={'82%'}
              isStopped={false}
              isPaused={false}
              speed={1}
            />

            <p
              style={{
                marginTop: 5,
                textAlign: 'center',
                fontSize: 16,
                padding: 10,
              }} >
              We fine-tune your networking suggestions based on context.
              Whether you're attending a conference, work event, or music festival,
              you'll always be in the loop and building your network.
            </p>

          </div>
        </div>

        <div className="col s4">
          <div style={{
              width: '100%',
              alignItems: 'center',
              alignSelf: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              justifySelf: 'center',
            }}>

            <p
              className={'acsa'}
              style={{
                marginTop: 25,
                fontFamily: "Open Sans",
                textAlign: 'center',
                fontSize: 24,
              }} >
              Meaning-driven
            </p>
            
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: meaning,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid'
                }
              }}
              width={'70%'}
              isStopped={false}
              isPaused={false}
              speed={1}
            />

            <p
              style={{
                textAlign: 'center',
                fontSize: 16,
                padding: 10,
              }} >
              Our Multi-Agent AI architecture ensures that we get to know what
              makes you tick, and what connections you will find most meaningful.
            </p>

          </div>
        </div>

        <div className="col s4">
          <div style={{
              width: '100%',
              alignItems: 'center',
              alignSelf: 'center',
              alignContent: 'center',
              justifyContent: 'center',
              justifyItems: 'center',
              justifySelf: 'center',
            }}>

            <p
              className={'acsa'}
              style={{
                marginTop: 25,
                fontFamily: "Open Sans",
                textAlign: 'center',
                fontSize: 24,
              }} >
              Chat-powered
            </p>
            
            <Lottie
              options={{
                loop: true,
                autoplay: true,
                animationData: message,
                rendererSettings: {
                  preserveAspectRatio: 'xMidYMid'
                }
              }}
              width={'80%'}
              isStopped={false}
              isPaused={false}
              speed={1}
            />

            <p
              style={{
                marginTop: -20,
                textAlign: 'center',
                fontSize: 16,
                padding: 10,
              }} >
              Connect in the most natural way by simply having a chat with us! No need to download any apps, a few WhatsApp messages is all it takes for you to start building your network.
            </p>

          </div>
        </div>

      </div>

        
    </section>
  }

  mainPointsSection = () => {
    return <section style = {{
        marginTop: window.innerHeight * 0.2,
        marginBottom: window.innerHeight * 0.3,
        display: 'flex',
        alignItems: 'center',
      }}>

        <div style={{
          width: '100%',
        }}>
          <Lottie
            options={{
              loop: true,
              autoplay: true,
              animationData: overview,
              rendererSettings: {
                preserveAspectRatio: 'xMidYMid'
              }
            }}
            width={'100%'}
            isStopped={false}
            isPaused={false}
            speed={1}
          />
        </div>

        <div style={{
          marginLeft: 50,
          width: '100%',
        }}>
          <h3
            className={'acsa'}
            style={{
              marginTop: 25,
              fontFamily: "Open Sans",
            }} >
            Networking made Easy
          </h3>

          <p
            style={{
              marginTop: 25,
              fontFamily: "Open Sans",
              fontSize: 20,
              lineHeight: 1.4,
            }} >
            
            With the <span className='tenx'>10x Network</span> you can:

            <ul>
              <li style={iconList}>
                <DesktopMacIcon
                  htmlColor={'#4D6CC5'}
                />
                <span style = {{marginLeft: 10, fontSize: 16,}}>
                  Connect with ease via chat.
                </span>
              </li>
              <li style={iconList}>
                <HomeWorkIcon
                  htmlColor={'#4D6CC5'}
                />
                <span style = {{marginLeft: 10, fontSize: 16,}}>
                  Get meaningful suggestions on who to connect with.
                </span>
              </li>
              <li style={iconList}>
                <BuildIcon
                  htmlColor={'#4D6CC5'}
                />
                <span style = {{marginLeft: 10, fontSize: 16,}}>
                  Constantly build and maintain your network.
                </span>
              </li>
              <li style={iconList}>
                <CloudUploadIcon
                  htmlColor={'#4D6CC5'}
                />
                <span style = {{marginLeft: 10, fontSize: 16,}}>
                  Stay informed with everything going on at a venue.
                </span>
              </li>
              <li style={iconList}>
                <PhoneIphoneIcon
                  htmlColor={'#4D6CC5'}
                />
                <span style = {{marginLeft: 10, fontSize: 16,}}>
                  Be the first to know about exciting new events that you'll love to join.
                </span>
              </li>
            </ul>

          </p>
        </div>
      
    </section>
  }
 
  render() {
    return <div class="container" style={{
      width: "80%",
    }}>
      
      {
        this.welcomeSection()
      }
      
      {
        this.engageSection()
      }

      {
        this.featuresSection()
      }

      {
        this.mainPointsSection()
      }

    </div>
  }
}

// const subtitleSmall = {
//   color: '#00627b',
//   fontStyle: 'italic'
// };
const iconList = {
  display:'inline-flex',
  alignItems: 'center',
  lineHeight: 1.4,
  marginBottom: 10,
};

export default App;
